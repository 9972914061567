
(function($){
    // Document Ready
    $(document).ready(function(){
      initMenuUniversHeader();
      initMenuUnivers();
      initFooter();
      initOverviewProduct();
      initMsgConfirmationFav("#msg-confirm-product-fav-modal");
      //initShoppingList();
      initComparatorProduct();
      initMenuMobile();
      //initQuickOrder('.quick-order-mobile');
      //initQuickOrder('.quick-order');
      handleIOSdevice();
      initHeaderDesktop();
    });
})(jQuery);

// -------------- VIEWPORT IOS ---------------------------------------- //
let handleIOSdevice = function () {
  const addMaximumScaleToMetaViewport = () => {
    const el = document.querySelector("meta[name=viewport]");

    if (el !== null) {
      let content = el.getAttribute("content");
      let re = /maximum\-scale=[0-9\.]+/g;

      if (re.test(content)) {
        content = content.replace(re, "maximum-scale=1.0");
      } else {
        content = [content, "maximum-scale=1.0"].join(", ");
      }

      el.setAttribute("content", content);
    }
  };

  const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
  const checkIsIOS = () =>
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (checkIsIOS()) {
    disableIosTextFieldZoom();
  }
};

// -------------- HEADER DESKTOP ---------------------------------------- //
let initHeaderDesktop = function () {
  let userLogged = $(".logged-user");
  let subMenuAccount = $(".sub-menu-account");

  if(userLogged) {
    userLogged.on("click", function() {
      subMenuAccount.toggleClass("open");
    });
  }

};
// -------------- MENU MOBILE ---------------------------------------- //
let initMenuMobile = function() {
  $btnMenuMobile = $(".btn-menu-mobile");
  $menuMobileContent = $(".menu-mobile-content");
  $btnCloseMenuMobile = $(".close-menu-main-mobile");
  $btnMenuUniversMobile = $("#mobile-univers");
  $menuUniversMobileContent = $(".menu-univers-mobile-content");
  $btnCloseMenuUniversMobile = $(".close-menu-univers-mobile");
  $linkToSpeedOrderMobile = $("#speed-order-mobile");

  // Main mobile menu
  $btnMenuMobile.on("click", function(){
    $menuMobileContent.toggleClass("open");
  });

  $btnCloseMenuMobile.on("click", function(){
    $menuMobileContent.toggleClass("open");
  });

  // Univers mobile menu
  $btnMenuUniversMobile.on("click", function(){
    $menuUniversMobileContent.addClass("open");
  });

  $btnCloseMenuUniversMobile.on("click", function(){
    $menuUniversMobileContent.removeClass("open");
  });

  // Sub family menu
  $itemsUnivers = $(".menu-univers-mobile-content .nav-item .nav-link");

  $itemsUnivers.each(function(){
    if($(this).next().hasClass("menu-sub-family-mobile-content")){
      $(this).on("click", function(){
        $(this).next().toggleClass("open");
      });

      $(this).next().find(".close-menu-sub-family-mobile").on("click", function(){
        if($(this).parent().hasClass("title-nav")) {
          $(this).parent().parent().parent().toggleClass("open");
        }

        if($(this).parent().hasClass("menu-sub-family-mobile-content__header")) {
          $(this).parent().parent().toggleClass("open");
        }
      });
    }
  });

  // Link Speed order
  $linkToSpeedOrderMobile.on("click", function(){
    $menuMobileContent.removeClass("open");

    if($menuUniversMobileContent.hasClass("open")) {$menuUniversMobileContent.removeClass("open");}
  });

};

// -------------- UNIVERS MENU ---------------------------------------- //
let initMenuUniversHeader = function () {
  const $universLink = $("#univers-link");
  const $menuUniversWrapper = $(".menu-univers-wrapper");
  const $closeBtn = $(".close-btn");
  const $menuUnivers = $(".univers-menu");
  const $bgUniversMenu = $(".bg-univers-menu");

  $universLink.on("click", function() {
    $menuUniversWrapper.removeClass("closed");
    $menuUnivers.removeClass("closed");
    $bgUniversMenu.removeClass("closed");
  });

  $closeBtn.on("click", function() {
    $menuUniversWrapper.addClass("closed");
    $menuUnivers.addClass("closed");
    $bgUniversMenu.addClass("closed");
  });
};

let initMenuUnivers = function () {
  const $menuUnivers = $(".test-univers-menu");
  const $navContainer = $(".nav-container");
  const $hasChildrens = $(".has-children");
  const $closeBtn = $(".close-btn");
  const $submenu = $(".submenu");
  const $subFamily = $(".sub-family");
  const $isChildrens = $(".is-children");

  $hasChildrens.each(function() {
    $(this).on("click", function() {
      $navContainer.find(".active").removeClass("active");
      $navContainer.find(".open-submenu-cat").removeClass("open-submenu-cat");
      $(this).addClass("active");
      $(this).children().addClass("open-submenu-cat");
      $navContainer.addClass("open-submenu-cat");
      $submenu.addClass("open-submenu-cat");
      $closeBtn.addClass("open-submenu-cat");
    });
  });

  for(let i = 0; i < $subFamily.length; i ++) {
    if($subFamily[i].parentElement.classList.contains("has-children")) {
      $subFamily[i].addEventListener("click", function () {
        $subFamily.removeClass("active-sub-family");
        $subFamily[i].classList.add("active-sub-family");
        $isChildrens.removeClass("open-children");
        document.querySelector(`.is-children-${i + 1}`).classList.add("open-children");
      });
    }
  }

  $closeBtn.on("click", function() {
    $menuUnivers.addClass("closed");
  });
};

// -------------- QUICK ORDER  ---------------------------------------- //
/**
 *
 * @param {string} parent - name of the class of the first parent element
 */
// let initQuickOrder = function(parent) {
//   /* --- Manage upload file input --- */
//   let $fileInput = $(`${parent} .file-input`);
//   let $droparea = $(`${parent} .file-drop-area`);
//   let $contentFileArea = $(`${parent} .content-file-area`);
//   let $userInstructions = $(`${parent} .user-instructions`);
//   let $reference = $(`${parent} #reference`);
//   let $substProdQuick = $(`${parent} .subst-prod-quick-order`);
//   let $addProdQuick = $(`${parent} .add-prod-quick-order`);
//   let $quantityQuick = $(`${parent} #quantityQuick`);
//   let $inputReference = $(`${parent} .by-reference input[name="reference"]`);
//   let $submitBtnFormByRef = $(`${parent} .by-reference button[type="submit"]`);
//   let $wrongRefMsg = $(`${parent} .by-reference .wrong-reference`);
//   let $csvUploadSubmit = $(`${parent} .csv-upload-submit`);
//   let $csvForm = document.querySelector(`${parent} #upload-csv`);
//   let $uploadAgainCsv = $(`${parent} .upload-again-csv`);
//   let $errorInRefCsv = $(`${parent} .error-in-ref-csv`);
//   let $limitSizeIndication = $(`${parent} .limit-size-indication`);

//   // highlight drag area
//   $fileInput.on('dragenter focus click', function() {
//     $droparea.addClass('is-active');
//   });

//   // back to normal state
//   $fileInput.on('dragleave blur drop', function() {
//     $droparea.removeClass('is-active');
//   });

//   // Function Convert bytes to MB
//   function readableBytes(bytes) {
//     return (bytes / Math.pow(1024, 2)).toFixed(2) * 1;
//   }

//   // Function Reset the upload file form
//   function resetUploadForm () {
//     let initialContentArea = `
//         <p class="icon">
//           <img src="/img/icons/upload_file.svg" alt="Déposer votre csv">
//         </p>
//         <p class="user-indication">
//           <span class="file-msg">Déposez votre fichier ici ou</span>
//           <span class="fake-btn">téléchargez-le</span>
//         </p>
//         <p class="limit-size-indication">
//           <span>.CSV jusqu'à 10MB</span>
//         </p>
//         `;

//         $contentFileArea.html(initialContentArea);

//         $fileInput.css('display', 'block');
//         $userInstructions.css('display', 'block');

//         $csvForm.reset();

//         $droparea.removeClass('file-uploaded-in-area');

//         // Remove references error message
//         if($errorInRefCsv) $errorInRefCsv.css('display', 'none');

//         // Restaure enable inputs on the left side of modal
//         $reference.attr('disabled', false);
//         $substProdQuick.removeAttr('aria-disabled');
//         $addProdQuick.removeAttr('aria-disabled');
//         $substProdQuick.attr('disabled', false);
//         $addProdQuick.attr('disabled', false);
//         $quantityQuick.attr('disabled', false);

//         // Restaure initial properties of submit btn
//         $csvUploadSubmit.text('Ajouter au panier');
//         $csvUploadSubmit.addClass('disabled');
//         $csvUploadSubmit.attr('disabled', true);
//   }

//   // Change input file
//   $fileInput.on('change', function() {
//     let filesCount = $(this)[0].files.length;
//     let fileSize = $(this)[0].files[0].size;

//     // *** A correct file is uploaded ***
//     if (filesCount === 1 && readableBytes(fileSize) <= 10) {
//       // if a file is selected, show file name
//       let fileName = $(this).val().split('\\').pop();
//       let newContentFileArea = `
//       <div class="uploaded-file">
//         <img class="icon-uploaded" src="/img/icons/upload_file_black.svg">
//         <p>${fileName} <span class="separator">.</span><span class="size">${readableBytes(fileSize)} MB</span></p>
//         <button class="btn" id="delete-file">
//           <img src="/img/icons/delete-red.svg" alt="supprimer le fichier" title="supprimer le fichier" >
//         </button>
//       </div>
//       `;
//       // Change the content of file area dragged and dropped
//       $contentFileArea.html(newContentFileArea);
//       // Change the border of the parent container

//       $droparea.addClass('file-uploaded-in-area');

//       // Remove the file input
//       $fileInput.css('display', 'none');

//       // Remove user instructions
//       $userInstructions.css('display', 'none');

//       // Disabled all input of the left side on this modal
//       $reference.attr('disabled', true);
//       $substProdQuick.attr('aria-disabled', true);
//       $addProdQuick.attr('aria-disabled', true);
//       $substProdQuick.attr('disabled', true);
//       $addProdQuick.attr('disabled', true);
//       $quantityQuick.attr('disabled', true);
//       if(!$submitBtnFormByRef.hasClass('disabled')) $submitBtnFormByRef.attr('disabled', true);
//       if(!$submitBtnFormByRef.hasClass('disabled')) $submitBtnFormByRef.addClass('disabled');

//       // Modify submit button
//       $csvUploadSubmit.text('Valider et ajouter au panier');
//       $csvUploadSubmit.removeClass('disabled');
//       $csvUploadSubmit.attr('disabled', false);

//       // prevent default
//       $csvForm.addEventListener('submit', function(e){
//         e.preventDefault();
//       })

//       // Enable the remove file button
//       let $removeFile = $(`${parent} #delete-file`);
//       $removeFile.on('click', resetUploadForm);

//       // Use upload again csv button
//       $uploadAgainCsv.on('click', resetUploadForm);
//     }

//     // ***  An size > 10 MB file is uploaded  ***
//     if(filesCount === 1 && readableBytes(fileSize) > 10) {
//       $limitSizeIndication.html(`
//       <span class="invalid-file">
//       Votre fichier dépasse le poids maximum autorisé (10 MB).
//       </span>
//       `);

//       $csvForm.reset();
//     }
//   });
//   /* --- END of manage upload file input --- */


//   /* --- Toogle display of invalid references --- */
//     let $displayError = $(`${parent} .display-error`);
//     let $invalidRefContent = $(`${parent} .invalid-ref-content`);
//     let $arrow = $(`${parent} .copy-content > button > .arrow`);

//     $displayError.on('click', function() {
//       $invalidRefContent.toggleClass('visible');
//       $arrow.toggleClass('open');
//     });
//   /* --- END of Toogle display of invalid references --- */

//   /* --- Copy invalid references  --- */
//     let $copyBtn = $(`${parent} .copy-content > .copy-btn`);
//     let $invalidRef = $(`${parent} .invalid-ref`);

//     if ($(window).width() < 576) {
//       $copyBtn.html(`<img src="/img/icons/content_copy.svg" class="icon-copy" alt="Copier les références" title="Copier les références">`)
//     }

//     $copyBtn.on('click', function(){
//       let refToCopy = $invalidRef.text().trim();
//         navigator.clipboard.writeText(refToCopy)
//           .then(() => {
//             $(this).html(`<img src="/img/icons/check_circle-green.svg" class="icon-copy" alt="Références copiées" title="Références copiées">
//                         Copié`)
//             setTimeout(()=> {
//               if ($(window).width() < 576) {
//                 $(this).html(`<img src="/img/icons/content_copy.svg" class="icon-copy" alt="Copier les références" title="Copier les références">`)
//               }

//               if ($(window).width() > 576) {
//                 $(this).html(`<img src="/img/icons/content_copy.svg" class="icon-copy" alt="Copier les références" title="Copier les références">
//                           Copier`)
//               }
//             }, 4000);
//           })
//           .catch((err)=> {
//             console.error('error', err);
//           })
//     })
//   /* --- END of Copy invalid references  --- */

//   /* --- Enabled add/subtract on quantity product --- */
//       $(`${parent} .btn-number-quick`).click(function (e) {
//       e.preventDefault()

//       let fieldName = $(this).attr('data-field')
//       let type = $(this).attr('data-type')
//       let input = $(`${parent} input[name='${fieldName}']`)
//       let currentVal = parseInt(input.val())

//       if (!isNaN(currentVal)) {
//         if (type === 'minus') {
//           if (currentVal > input.attr('min')) {
//             input.val(currentVal - 1).change()
//           }
//           if (parseInt(input.val()) === input.attr('min')) {
//             $(this).attr('disabled', true)
//           }
//         } else if (type === 'plus') {
//           if (currentVal < input.attr('max')) {
//             input.val(currentVal + 1).change()
//           }
//           if (parseInt(input.val()) === input.attr('max')) {
//             $(this).attr('disabled', true)
//           }
//         }
//       } else {
//         input.val(0)
//       }
//     })

//     let $inputNumber = $(`${parent} .input-number-quick`)

//     $inputNumber.focusin(function () {
//       $(this).data('oldValue', $(this).val())
//     })

//     $inputNumber.change(function () {
//       let minValue = parseInt($(this).attr('min'))
//       let maxValue = parseInt($(this).attr('max'))
//       let valueCurrent = parseInt($(this).val())
//       let name = $(this).attr('name')

//       if (valueCurrent >= minValue) {
//         $(`${parent} .btn-number-quick[data-type='minus'][data-field='${name}']`).removeAttr('disabled')
//       } else {
//         $(this).val($(this).data('oldValue'))
//       }
//       if (valueCurrent <= maxValue) {
//         $(`${parent} .btn-number-quick[data-type='plus'][data-field='${name}']`).removeAttr('disabled')
//       } else {
//         $(this).val($(this).data('oldValue'))
//       }
//     })

//     $inputNumber.keydown(function (e) {
//       // Allow: backspace, delete, tab, escape, enter and .
//       if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
//         // Allow: Ctrl+A
//         (e.keyCode === 65 && e.ctrlKey === true) ||
//         // Allow: home, end, left, right
//         (e.keyCode >= 35 && e.keyCode <= 39)) {
//         // let it happen, don't do anything
//         return
//       }
//     });
//   /* --- END of Enabled add/subtract on quantity product --- */

//   /* --- Manage the content first form by reference --- */
//     $inputReference.on('change', function() {
//       let regexReference = /^\d{9}$/;
//       if($wrongRefMsg.hasClass('visible')) $wrongRefMsg.removeClass('visible')

//       // Reference value validator
//       if($(this).val().length === 9 &&
//          regexReference.test(parseInt($(this).val()))) {

//           $submitBtnFormByRef.removeAttr('disabled');
//           $submitBtnFormByRef.removeClass('disabled');
//         } else {
//           if(!$submitBtnFormByRef.hasClass('disabled')) $submitBtnFormByRef.attr('disabled');
//           if(!$submitBtnFormByRef.hasClass('disabled')) $submitBtnFormByRef.addClass('disabled');
//           $wrongRefMsg.addClass('visible').html(`
//           <img src="/img/icons/cancel.svg">
//           <p>La référence <span>${$(this).val()}</span> est erronée</p>
//           `)
//         }

//       // Reference value is empty
//       if($(this).val().length === 0 || $(this).val() === "") {
//         if(!$submitBtnFormByRef.hasClass('disabled')) $submitBtnFormByRef.attr('disabled');
//         if(!$submitBtnFormByRef.hasClass('disabled')) $submitBtnFormByRef.addClass('disabled');
//         $wrongRefMsg.addClass('visible').html(`
//         <img src="/img/icons/cancel.svg">
//         <p>Veuillez indiquer une référence</p>
//         `)
//       }
//     });
// }

// -------------- TREATMENT OF MAIN FOOTER ON MOBILE VIEW ------------- //
let initFooter = function () {
  if ($( window ).width() < 768) {
    const $h2 = $(".heading-wrap");
    $h2.each(function() {
      $(this).on("click", function() {
        $(this).parent().children(".menu-footer").children("ul").toggleClass("visible");
        $(this).find("h2").toggleClass("open");
        $(this).find("img").toggleClass("open");
      });
    });
  }
};

// -------------- PRODUCT OVERVIEW ---------------------------------------- //
let initOverviewProduct = function () {
  // ** Handle comparator checkbox **
  $productComparator = $(".product-comparator");
  $compareInput = $(".compare-input");
  $compareLabel = $(".compare-label");

  $compareInput.each(function() {
    $(this).on("mouseover", function() {
      $(this).parent(".product-comparator").toggleClass("hover");
      $(this).next(".compare-label").css("display", "block");
    });

    $(this).on("mouseout", function() {
      $(this).parent(".product-comparator").toggleClass("hover");
      $(this).next(".compare-label").css("display", "none");
    });

    $(this).on("focus", function() {
      $(this).parent(".product-comparator").toggleClass("hover");
      $(this).next(".compare-label").css("display", "block");
    });

    $(this).on("focusout", function() {
      $(this).parent(".product-comparator").toggleClass("hover");
      $(this).next(".compare-label").css("display", "none");
    });

    $(this).on("click", function(){
      if($(this).is(":checked")){
        $(this).parent(".product-comparator").removeClass("hover").css("background-color", "transparent");
        $(this).next(".compare-label").css("display", "none");
      }
    });

  });

  // ** Handle add in favorite list **
  // $addFavBtn = $('.can-to-be-add-fav');
  // $addedTofav = $('.added-in-fav-list');

  // // Add a product in a favorite list
  // $addFavBtn.each(function() {
  //   $(this).on('click', function() {
  //     if(! $(this).hasClass('added-in-fav-list')) {

  //       $(this).removeClass('can-to-be-add-fav');
  //       $(this).html('<a><img src="/img/icons/favorite-filled-red.svg" class="added-to-fav" alt="Ajouter au favori"></a>');
  //       $(this).addClass('added-in-fav-list');

  //     }
  //   })
  // })

  // Remove a product of a favorite list | TO DO - WIP
  // $addedTofav.each(function() {
  //   $(this).on('click', function(event) {
  //     console.log($(this));
  //     console.log('test');
  //     // if( $(this).parent().hasClass('added-in-fav-list')) {
  //     //   console.log('removed of fav')
  //     // }
  //   })
  // })

  // Truncate product name when its length > 55
  let productNames = document.querySelectorAll(".product__name");

  for(let productName of productNames) {
    if(productName.textContent.length > 55) {
      let truncated = productName.textContent.substring(0, 55) + "...";
      productName.textContent = truncated;
    }
  }
};
// -------------- MODALS MSG CONFIRMATION PRODUCT ADDED IN FAVORITE ----------- //
let initMsgConfirmationFav = function (parent) {
  let $confirmSaveProduct = $(`${parent} .confirm-save-product`);
  let $modals = $(".modal");

  $confirmSaveProduct.on("click", function() {

    $modals.each( function() {
      if($(this).hasClass("show")) {
        $(this).modal("hide");
      }
    });
  });
};


// -------------- COMPARATOR ----------- //

let initComparatorProduct = function () {
  // ** Handle Custom tooltip **
  let $stockState = $(".products-comparator-wrapper").find(".stock-state");
  let $customTooltip = $(".products-comparator-wrapper").find(".tooltip-custom");
  let $nbStock = $(".nb-stock");

  $stockState.each(function() {
    $(this).on("click", function(e) {
      e.stopPropagation();
      $(this).next().toggleClass("visible");
    });
  });

  $(document).on("click", function(e) {
    $customTooltip.each(function(){
      if(!$(this).is(e.target) && $(this).has(e.target).length === 0) {
        if($(this).hasClass("visible")) {$(this).removeClass("visible");}
      }
    });
  });

  $nbStock.each(function() {
    if($(this).text() == "0") {
      $(this).addClass("out-of-stock");
    }
  });

  // ** Handle the btn of second modal for empty the comparator **
  let $emptyComparator = $("#viewComparatorModal").find(".empty-comparator");
  let $modals = $(".modal");
  $emptyComparator.on("click", function() {
    $modals.each( function() {
      if($(this).hasClass("show")) {
        $(this).modal("hide");
      }
    });
  });
};

// -------------- HANDLE DISPLAY OF AMOUNTS NUMBERS ----------- //
// let initShoppingList = function () {
//   // Format numbers after thousand
//   $unitPrice = $('.unit');
//   $totalOneProduct = $('.rate-one-product');
//   $subTotal = $('.sub-total');
//   $ecoPart = $('.eco-part');
//   $tva = $('.tva');
//   $totalTtc = $('.total-ttc');
//
//   function numberWithSpaces(x) {
//     //var parts = x.toString().split(/(,|\.)/);
//     var parts = x.toString().split(/[\.,]/);
//     parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
//     return parts.join(",");
//   }
//
//   for(let i = 0; i < $unitPrice.length; i ++) {
//     $unitPrice[i].textContent = numberWithSpaces(Number($unitPrice[i].textContent).toFixed(2));
//   }
//
//   for(let i = 0; i < $totalOneProduct.length; i ++) {
//     $totalOneProduct[i].textContent = numberWithSpaces(Number($totalOneProduct[i].textContent).toFixed(2));
//   }
//
//   for(let i = 0; i < $subTotal.length; i ++) {
//     $subTotal[i].textContent = numberWithSpaces(Number($subTotal[i].textContent).toFixed(2));
//   }
//
//   for(let i = 0; i < $ecoPart.length; i ++) {
//     $ecoPart[i].textContent = numberWithSpaces(Number($ecoPart[i].textContent).toFixed(2));
//   }
//
//   for(let i = 0; i < $tva.length; i ++) {
//     $tva[i].textContent = numberWithSpaces(Number($tva[i].textContent).toFixed(2));
//   }
//
//   for(let i = 0; i < $totalTtc.length; i ++) {
//     $totalTtc[i].textContent = numberWithSpaces(Number($totalTtc[i].textContent).toFixed(2));
//   }
//
// }